import React, { useState, useEffect } from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function BotHistory() {
  const [stripe, setStripe] = React.useState("odd");
  const [history, setHistory] = React.useState();
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const itemsPerPage = 20; // Change this according to your requirement
  const totalDataCount = count; // Change this according to your total data count
  const countnumber = Math.ceil(totalDataCount / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
    console.log("page", value);
  };

  console.log("history", history);

  const GetHistory = async (e) => {
    // e.preventDefault();
    try {
      // const combinedData = {
      //     selected_pdf: Userdata.pdf_file ? Userdata.pdf_file.name : Userdata.selected_pdf ? Userdata.selected_pdf : "blank",
      //     userInput: Userdata.userInput,
      //     pdf_file: Userdata.pdf_file
      // };

      // console.log("combinedData", combinedData)
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/history/`, {
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // },
        page: page,
      });
      setCount(response.data.total_items);
      // console.log("response", response.data.total_items)
      setHistory(response.data.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    GetHistory();
  }, [page]);
  return (
    <Sheet>
      <Table aria-label="striped table" stripe={stripe}>
        <thead style={{ fontSize: "17px" }}>
          <tr>
            <th
              style={{ width: "5%", backgroundColor: "#333", color: "white" }}
            >
              S.No.
            </th>
            <th
              style={{ width: "5%", backgroundColor: "#333", color: "white" }}
            >
              Type
            </th>
            <th
              style={{ width: "75%", backgroundColor: "#333", color: "white" }}
            >
              Message
            </th>
            <th
              style={{ width: "10%", backgroundColor: "#333", color: "white" }}
            >
              Time
            </th>
          </tr>
        </thead>
        <tbody>
          {history
            ? history?.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.message_type}</td>
                <td>{row.message}</td>
                <td>
                  {new Date(row.timestamp).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </td>
              </tr>
            ))
            : "No data"}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <Stack
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={countnumber}
                  page={page}
                  onChange={handleChange}
                />
              </Stack>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}
