// App.js

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatComponent from "./Pages/Bot";
import BotHistory from "./Pages/botHistory";
import AdminComponent from "./Pages/Admin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ChatComponent />} />
        <Route path="/history" element={<BotHistory />} />
        <Route path="/admin" element={<AdminComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
